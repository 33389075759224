const payment = {
  state: {
    beginUpdate: new Date(),
    contractSummary: { amount: 0 },
    contract: null,
    isEdit: false,
    showLeft: false,
    summaryType: '1'
  },
  mutations: {
    set_payment_update (state, data) {
      state.beginUpdate = data
    },
    set_payment_contractSummary (state, data) {
      state.contractSummary = data
    },
    set_payment_chkContract (state, data) {
      state.contract = data
    },
    set_payment_isEdit (state, data) {
      state.isEdit = data
    },
    set_payment_showLeft (state, data) {
      state.showLeft = data
    },
    set_payment_summaryType (state, data) {
      state.summaryType = data
    }
  }
}

export default payment
