<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          ref="bottom"
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import paymentStoreModule from '@/store/modules/payment'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom'),
    Left: () => import('./components/Left'),
    ContractComponent: () => import('./components/ContractSummary'),
    PaymentComponent: () => import('./components/PaymentSummary'),
    InvoicingComponent: () => import('./components/InvoicingSummary')
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
    showLeft (val) {
      this.setLeftComponent(val ? 'Left' : '')
      this.setShowLeftBlock(val)
    },
    summaryType (val) {
      let rightComponent = 'ContractComponent'
      switch (parseInt(val)) {
        case 1:
          rightComponent = 'ContractComponent'
          break
        case 2:
          rightComponent = 'PaymentComponent'
          break
        case 3:
          rightComponent = 'InvoicingComponent'
          break
      }
      this.setRightComponent(rightComponent)
    }
  },
  computed: {
    showLeft () {
      return this.$store.state.payment ? this.$store.state.payment.showLeft : false
    },
    summaryType () {
      return this.$store.state.payment ? this.$store.state.payment.summaryType : '1'
    }
  },
  mounted () {
    if (!this.$store.hasModule('payment')) {
      this.$store.registerModule('payment', paymentStoreModule)
    }
    this.setLeftComponent('')
    this.setRightComponent('ContractComponent')
    this.setShowLeftBlock(false)
    this.setShowRightBlock(true)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('payment')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
